<template>
    <v-container>
        <nav class="navbar navbar-light bg-light">
            <h3 >SCORE</h3>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <span id="idBout" class="h3"></span>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <span id="idName" class="h3"></span>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <span id="idMode" class="h3"></span>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="count()"
                data-toggle="modal" data-target="#modalResult"
            >
                Count
            </v-btn>
            <div class="modal fade" id="modalResult" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title">Total Score</h2>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="h4 text-danger">Deduction: {{deduction}}</p>
                        <p class="h3">Score: {{final}}</p>
                    </div>
                    <div class="modal-footer">
                        <v-btn
                            v-if="modeParty == 'Double D2'"
                            color="blue darken-1"
                            text
                            data-toggle="modal" data-target="#singeDoubleResult"
                            @click="countTwicePerform()"
                        >
                            Final Result
                        </v-btn>
                    </div>
                    </div>
                </div>
            </div><div class="modal fade" id="singeDoubleResult" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title">Final Result</h2>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="h3">First: {{finalFirst}}</p>
                        <p class="h3">Second: {{finalSecond}}</p>
                        <p class="h2">Final Score: {{finalTwicePerform}}</p>
                    </div>
                    <div class="modal-footer">
                    </div>
                    </div>
                </div>
            </div>
        </nav>
        <table id="datatable" class="table table-striped table-dark h4" >
            <thead >
                <tr>
                    <th>Judge Name</th>
                    <th>Judge Role</th>
                    <th>M</th>
                    <th>P</th>
                    <th>A</th>
                    <th>CF</th>
                    <th>SCR</th>
                </tr>
            </thead>
            <tbody id = "idTable">
            </tbody>
        </table>
    </v-container>
</template>



<script>

    // Add the Firebase products that you want to use
    import * as firebase from 'firebase/app'
    import 'firebase/auth'
    import 'firebase/database'

    export default {
        data: () => ({
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                    text: 'Judge Name',
                    align: 'start',
                    sortable: false,
                    value: 'senderName',
                    class: "success--text title"
                },
                { text: 'Judge Role', value: 'role', class: "success--text title"},
                { text: 'M', value: 'm', class: "success--text title"},
                { text: 'P', value: 'p', class: "success--text title"},
                { text: 'A', value: 'a', class: "success--text title"},
                { text: 'FP', value: 'fp', class: "success--text title"},
                { text: 'NA', value: 'na', class: "success--text title"},
                // { text: 'Action', value: 'actions', sortable: false },
            ],
            score: [],
            finalScore: [],
            scoreSecond: [],
            finalScoreSecond: [],
            final: 0,
            finalFirst: 0,
            finalSecond: 0,
            finalTwicePerform: 0,
            maxMember: '',
            bout: '',
            deduction: '',
            deductionFirst: '',
            deductionSecond: '',
            name: '',
            team: '',
            modeParty: '',
            arenaUid: '',
            editedIndex: -1,
        }),

        //tambahkan attribute firebase disini

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        beforeCreate: function() {
            if (!this.$session.exists()) {
            this.$router.push({ name: "Login" });
            }
        },

        methods: {
            count(){
                var temp, temp1, lastCounter, nameParticipant, teamParticipant, mode;
                firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message").limitToLast(1)
                .once('value', function(snapshot){
                    if (snapshot.exists()) {
                        snapshot.forEach(function(childSnapshot) {
                            if(childSnapshot.val().noParticipant != ''){
                                lastCounter = childSnapshot.val().noParticipant;
                                nameParticipant = childSnapshot.val().name;
                                teamParticipant = childSnapshot.val().team;
                                mode = childSnapshot.val().mode;
                            }
                        });
                    }
                }).then(()=>{
                    this.modeParty = mode;
                    firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message")
                    .once('value', function(snapshot){
                        if (snapshot.exists()) {
                            var returnArr = [];
                            var flm, flp, fla, fp, na;
                            snapshot.forEach(function(childSnapshot) {
                                var item = childSnapshot.val();
                                item.key = childSnapshot.key;
                                
                                if(childSnapshot.val().role != 'Operator' && childSnapshot.val().noParticipant == lastCounter && childSnapshot.val().mode == mode){
                                    if(childSnapshot.val().role != 'Referee'){
                                        item.role = childSnapshot.val().role + " " + childSnapshot.val().no;
                                    }

                                    flm = parseFloat(childSnapshot.val().m);
                                    flp = parseFloat(childSnapshot.val().p);
                                    fla = parseFloat(childSnapshot.val().a);

                                    fp = (flm + flp)/2;
                                    na = fp - fla;
                                    item.fp = parseFloat(fp).toFixed(2);
                                    item.na = parseFloat(na).toFixed(2);

                                    returnArr.push(item);
                                }
                            });
                            //sort
                            var byFp = returnArr.slice(0);
                            byFp.sort(function(a,b) {
                                return b.fp - a.fp;
                            });
                            var size = byFp.length, top, bottom, cekTop = 0, cekBottom = 0;
                            var tempList = [];

                            for (var i = 0; i < size; i++) {
                                if(i == 0){
                                    top = byFp[i].fp;
                                }
                                if(i == size - 1){
                                    bottom = byFp[i].fp;
                                }
                            }

                            for (var j = 0; j < size; j++) {
                                if(byFp[j].fp == top){
                                    cekTop++;
                                }
                            }
                            for (var k = 0; k < size; k++) {
                                if(byFp[k].fp == bottom){
                                    cekBottom++;
                                }
                            }

                            if(cekTop > 1){
                                //top
                                for (var l = 0; l < cekTop; l++) {
                                    tempList.push(byFp[l]);
                                }

                                var tempTop = tempList.slice(0);
                                tempTop.sort(function(a,b) {
                                    return a.a - b.a;
                                });

                                for (var m = 0; m < cekTop; m++) {
                                    byFp[m] = tempTop[m];
                                }
                            }
                            tempList = [];
                            if(cekBottom > 1){
                                //bottom
                                for (var n = size - cekBottom; n < size; n++) {
                                    tempList.push(byFp[n]);
                                }

                                var tempBottom = tempList.slice(0);
                                tempBottom.sort(function(a,b) {
                                    return a.a - b.a;
                                });
                                
                                var p = 0;

                                
                                for (var o = size - cekBottom; o < size; o++) {
                                    byFp[o] = tempBottom[p];
                                    p = p + 1;
                                }
                            }

                            temp = byFp;
                        }else {
                            console.log("No data available");
                        }
                    })
                    .then(()=>{
                        // get arena member
                        var member;
                        firebase.database().ref('Groups/'+ this.$session.get("arenaUid"))
                        .once('value', function(snapshot){
                            if (snapshot.exists()) {
                                member = snapshot.val().maxMember;
                            }else {
                                console.log("No data available");
                            }
                        })
                        .then(()=>{
                            var first, second;
                            firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message")
                            .once('value', function(snapshot){
                                if (snapshot.exists()) {
                                    
                                    snapshot.forEach(function(childSnapshot) {
                                        var item = childSnapshot.val();
                                        item.key = childSnapshot.key;
                                        if(childSnapshot.val().role == 'Operator' && childSnapshot.val().noParticipant == lastCounter){
                                            if(childSnapshot.val().mode == 'Double D1' || childSnapshot.val().mode == 'Single'){
                                                first = childSnapshot.val().deduction;
                                            }else
                                            if(childSnapshot.val().mode == 'Double D2'){
                                                second = childSnapshot.val().deduction;
                                            }
                                        }
                                    });  
                                }else {
                                    console.log("No data available");
                                }
                            })
                            .then(()=>{
                                this.deductionFirst = first;
                                this.deductionSecond = second;
                                this.maxMember =  member;
                                this.bout = lastCounter;
                                this.name = nameParticipant;
                                this.team = teamParticipant;

                                this.score = temp;
                                this.finalScore = temp;

                                //count
                                temp1 = 0;
                                var divider = 3;
                                temp = this.finalScore;
                                if(this.maxMember == 5){
                                    temp.forEach((value, index) => {
                                        if(index == 0 || index == 4){
                                            temp[index].na = 0;
                                            temp[index].m = "";
                                            temp[index].p = "";
                                            temp[index].a = "";
                                            temp[index].fp = ""
                                        }
                                    });
                                    divider = 3;
                                }else{
                                    if(this.maxMember == 7){
                                        temp.forEach((value, index) => {
                                            if(index == 0 || index == 6){
                                                temp[index].na = 0;
                                                temp[index].m = "";
                                                temp[index].p = "";
                                                temp[index].a = "";
                                                temp[index].fp = ""
                                            }
                                        });
                                        divider = 5
                                    }
                                }

                                temp.forEach((value) => {
                                    temp1 = temp1 + parseFloat(value.na);
                                });
                                
                                temp1 = temp1/divider;
                                // this.final = Math.floor(parseFloat(temp1).toFixed(3)*1000)/1000;
                                this.final = Math.floor(temp1*1000)/1000;


                                if(this.modeParty == 'Single' || this.modeParty == 'Double D1'){
                                    this.final = this.final - parseFloat(this.deductionFirst);
                                    this.deduction = this.deductionFirst;
                                }else{
                                    this.final = this.final - parseFloat(this.deductionSecond);
                                    this.deduction = this.deductionSecond;
                                }
                                this.final = parseFloat(this.final).toFixed(3);
                            });
                        });
                        
                    })
                    .catch(function(error) {
                        console.error(error);
                    });
                });
            },

            countTwicePerform(){
                var temp, temp1, lastCounter;
                firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message").limitToLast(1)
                .once('value', function(snapshot){
                    if (snapshot.exists()) {
                        snapshot.forEach(function(childSnapshot) {
                            if(childSnapshot.val().noParticipant != ''){
                                lastCounter = childSnapshot.val().noParticipant;
                            }
                        });
                    }
                }).then(()=>{
                    firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message")
                    .once('value', function(snapshot){
                        if (snapshot.exists()) {
                            var returnArr = [], returnArrSecond = [];
                            var flm, flp, fla, fp, na;
                            snapshot.forEach(function(childSnapshot) {
                                var item = childSnapshot.val();
                                item.key = childSnapshot.key;
                                
                                if(childSnapshot.val().role != 'Operator' && childSnapshot.val().noParticipant == lastCounter){
                                    if(childSnapshot.val().role != 'Referee'){
                                        item.role = childSnapshot.val().role + " " + childSnapshot.val().no;
                                    }

                                    flm = parseFloat(childSnapshot.val().m);
                                    flp = parseFloat(childSnapshot.val().p);
                                    fla = parseFloat(childSnapshot.val().a);

                                    fp = (flm + flp)/2;
                                    na = fp - fla;
                                    item.fp = parseFloat(fp).toFixed(2);
                                    item.na = parseFloat(na).toFixed(2);

                                    if(childSnapshot.val().mode == 'Single' || childSnapshot.val().mode == 'Double D1' ){
                                        returnArr.push(item);
                                    }else{
                                        returnArrSecond.push(item);
                                    }                                    
                                }
                            });
                            //sort
                            //first
                            var byFp = returnArr.slice(0);
                            byFp.sort(function(a,b) {
                                return b.fp - a.fp;
                            });
                            var size = byFp.length, top, bottom, cekTop = 0, cekBottom = 0;
                            var tempList = [];

                            for (var i = 0; i < size; i++) {
                                if(i == 0){
                                    top = byFp[i].fp;
                                }
                                if(i == size - 1){
                                    bottom = byFp[i].fp;
                                }
                            }

                            for (var j = 0; j < size; j++) {
                                if(byFp[j].fp == top){
                                    cekTop++;
                                }
                            }
                            for (var k = 0; k < size; k++) {
                                if(byFp[k].fp == bottom){
                                    cekBottom++;
                                }
                            }

                            if(cekTop > 1){
                                //top
                                for (var l = 0; l < cekTop; l++) {
                                    tempList.push(byFp[l]);
                                }

                                var tempTop = tempList.slice(0);
                                tempTop.sort(function(a,b) {
                                    return a.a - b.a;
                                });

                                for (var m = 0; m < cekTop; m++) {
                                    byFp[m] = tempTop[m];
                                }
                            }
                            tempList = [];
                            if(cekBottom > 1){
                                //bottom
                                for (var n = size - cekBottom; n < size; n++) {
                                    tempList.push(byFp[n]);
                                }

                                var tempBottom = tempList.slice(0);
                                tempBottom.sort(function(a,b) {
                                    return a.a - b.a;
                                });
                                
                                var p = 0;

                                for (var o = size - cekBottom; o < size; o++) {
                                    byFp[o] = tempBottom[p];
                                    p = p + 1;
                                }
                            }

                            temp = byFp;


                            //sort
                            //second
                            var byFpSecond = returnArrSecond.slice(0);
                            byFpSecond.sort(function(a,b) {
                                return b.fp - a.fp;
                            });
                            size = byFpSecond.length;
                            cekTop = 0;
                            cekBottom = 0;
                            tempList = [];

                            for (i = 0; i < size; i++) {
                                if(i == 0){
                                    top = byFpSecond[i].fp;
                                }
                                if(i == size - 1){
                                    bottom = byFpSecond[i].fp;
                                }
                            }

                            for (j = 0; j < size; j++) {
                                if(byFpSecond[j].fp == top){
                                    cekTop++;
                                }
                            }
                            for (k = 0; k < size; k++) {
                                if(byFpSecond[k].fp == bottom){
                                    cekBottom++;
                                }
                            }

                            if(cekTop > 1){
                                //top
                                for (l = 0; l < cekTop; l++) {
                                    tempList.push(byFpSecond[l]);
                                }

                                tempTop = tempList.slice(0);
                                tempTop.sort(function(a,b) {
                                    return a.a - b.a;
                                });

                                for (m = 0; m < cekTop; m++) {
                                    byFpSecond[m] = tempTop[m];
                                }
                            }
                            tempList = [];
                            if(cekBottom > 1){
                                //bottom
                                for (n = size - cekBottom; n < size; n++) {
                                    tempList.push(byFpSecond[n]);
                                }

                                tempBottom = tempList.slice(0);
                                tempBottom.sort(function(a,b) {
                                    return a.a - b.a;
                                });
                                
                                p = 0;

                                for (o = size - cekBottom; o < size; o++) {
                                    byFpSecond[o] = tempBottom[p];
                                    p = p + 1;
                                }
                            }

                            temp1 = byFpSecond;
                        }else {
                            console.log("No data available");
                        }
                    })
                    .then(()=>{
                        this.score = temp;
                        this.finalScore = temp;

                        this.scoreSecond = temp1;
                        this.finalScoreSecond = temp1;
                        //count
                        temp1 = 0;
                        var divider = 3;
                        temp = this.finalScore;
                        if(this.maxMember == 5){
                            temp.forEach((value, index) => {
                                if(index == 0 || index == 4){
                                    temp[index].na = 0;
                                    temp[index].m = "";
                                    temp[index].p = "";
                                    temp[index].a = "";
                                    temp[index].fp = ""
                                }
                            });
                            divider = 3;
                        }else{
                            if(this.maxMember == 7){
                                temp.forEach((value, index) => {
                                    if(index == 0 || index == 6){
                                        temp[index].na = 0;
                                        temp[index].m = "";
                                        temp[index].p = "";
                                        temp[index].a = "";
                                        temp[index].fp = ""
                                    }
                                });
                                divider = 5
                            }
                        }

                        temp.forEach((value) => {
                            temp1 = temp1 + parseFloat(value.na);
                        });
                        

                        temp1 = temp1/divider;
                        this.finalFirst = Math.floor(temp1*1000)/1000;
                        // this.finalFirst = parseFloat(temp1).toFixed(2);
                        

                        this.finalFirst = this.finalFirst - parseFloat(this.deductionFirst);
                        this.finalFirst = parseFloat(this.finalFirst).toFixed(3);

                        //count
                        //Second
                        temp1 = 0;
                        divider = 3;
                        temp = this.finalScoreSecond;
                        if(this.maxMember == 5){
                            temp.forEach((value, index) => {
                                if(index == 0 || index == 4){
                                    temp[index].na = 0;
                                    temp[index].m = "";
                                    temp[index].p = "";
                                    temp[index].a = "";
                                    temp[index].fp = ""
                                }
                            });
                            divider = 3;
                        }else{
                            if(this.maxMember == 7){
                                temp.forEach((value, index) => {
                                    if(index == 0 || index == 6){
                                        temp[index].na = 0;
                                        temp[index].m = "";
                                        temp[index].p = "";
                                        temp[index].a = "";
                                        temp[index].fp = ""
                                    }
                                });
                                divider = 5
                            }
                        }

                        temp.forEach((value) => {
                            temp1 = temp1 + parseFloat(value.na);
                        });

                        temp1 = temp1/divider;
                        this.finalSecond = Math.floor(temp1*1000)/1000;
                        // this.finalSecond = parseFloat(temp1).toFixed(2);
                        
                        this.finalSecond = this.finalSecond - parseFloat(this.deductionSecond);
                        this.finalSecond = parseFloat(this.finalSecond).toFixed(3);

                        temp = this.finalFirst;
                        temp1 = this.finalSecond;

                        this.finalTwicePerform = parseFloat(this.finalFirst) + parseFloat(this.finalSecond);
                        this.finalTwicePerform = this.finalTwicePerform/2;
                        this.finalTwicePerform = this.finalTwicePerform.toFixed(3);
                        
                    })
                    .catch(function(error) {
                        console.error(error);
                    });
                });
            },

            getScore(){
                //load data bout
                var temp, temp1, lastCounter, nameParticipant, teamParticipant, mode;
                firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message").limitToLast(1)
                .on('value', function(snapshot){
                    if (snapshot.exists()) {
                        snapshot.forEach(function(childSnapshot) {
                            if(childSnapshot.val().noParticipant != ''){
                                lastCounter = childSnapshot.val().noParticipant;
                                nameParticipant = childSnapshot.val().name;
                                teamParticipant = childSnapshot.val().team;
                                mode = childSnapshot.val().mode;
                                document.getElementById("idName").innerHTML = "Name - Team: " + nameParticipant + " - " + teamParticipant;
                                document.getElementById("idBout").innerHTML = "Bout: " + lastCounter;
                                document.getElementById("idMode").innerHTML = "Mode: " + mode;
                            }
                        });
                        
                    }
                });

                //load data score
                firebase.database().ref('Groups/'+ this.$session.get("arenaUid") +"/Message")
                .on('value', function(snapshot){
                    if (snapshot.exists()) {
                        var returnArr = [];
                        var flm, flp, fla, fp, na;
                        snapshot.forEach(function(childSnapshot) {
                            var item = childSnapshot.val();
                            item.key = childSnapshot.key;
                            
                            if(childSnapshot.val().role != 'Operator' && childSnapshot.val().noParticipant == lastCounter && childSnapshot.val().mode == mode){
                                if(childSnapshot.val().role != 'Referee'){
                                    item.role = childSnapshot.val().role + " " + childSnapshot.val().no;
                                }

                                flm = parseFloat(childSnapshot.val().m);
                                flp = parseFloat(childSnapshot.val().p);
                                fla = parseFloat(childSnapshot.val().a);

                                fp = (flm + flp)/2;
                                na = fp - fla;
                                item.fp = parseFloat(fp).toFixed(2);
                                item.na = parseFloat(na).toFixed(2);

                                returnArr.push(item);
                            }
                        });
                        //sort
                        var byFp = returnArr.slice(0);
                        byFp.sort(function(a,b) {
                            return b.fp - a.fp;
                        });
                        var size = byFp.length, top, bottom, cekTop = 0, cekBottom = 0;
                        var tempList = [];

                        for (var i = 0; i < size; i++) {
                            if(i == 0){
                                top = byFp[i].fp;
                            }
                            if(i == size - 1){
                                bottom = byFp[i].fp;
                            }
                        }

                        for (var j = 0; j < size; j++) {
                            if(byFp[j].fp == top){
                                cekTop++;
                            }
                        }
                        for (var k = 0; k < size; k++) {
                            if(byFp[k].fp == bottom){
                                cekBottom++;
                            }
                        }

                        if(cekTop > 1){
                            //top
                            for (var l = 0; l < cekTop; l++) {
                                tempList.push(byFp[l]);
                            }

                            var tempTop = tempList.slice(0);
                            tempTop.sort(function(a,b) {
                                return a.a - b.a;
                            });

                            for (var m = 0; m < cekTop; m++) {
                                byFp[m] = tempTop[m];
                            }
                        }
                        tempList = [];
                        if(cekBottom > 1){
                            //bottom
                            for (var n = size - cekBottom; n < size; n++) {
                                tempList.push(byFp[n]);
                            }

                            var tempBottom = tempList.slice(0);
                            tempBottom.sort(function(a,b) {
                                return a.a - b.a;
                            });
                            var p = 0;
                            for (var o = size - cekBottom; o < size; o++) {
                                byFp[o] = tempBottom[p];
                                p = p + 1;
                            }
                        }

                        temp = byFp;
                        temp1 = byFp;

                        var str = '';
                        for (var a = 0; a < temp.length; a++) {
                            str += '<tr><td>' + temp[a].senderName + '</td><td>' + temp[a].role + '</td><td>' + temp[a].m + '</td><td>' + temp[a].p + '</td><td>' + temp[a].a + '</td><td> ' + temp[a].fp + '</td><td>' + temp[a].na + '</td></tr>';
                        }
                        document.getElementById("idTable").innerHTML = str;
                    }else {
                        console.log("No data available");
                    }
                });
                
                var member;
                firebase.database().ref('Groups/'+ this.$session.get("arenaUid"))
                .on('value', function(snapshot){
                    if (snapshot.exists()) {
                        member = snapshot.val().maxMember;
                        this.maxMember =  member;
                    }else {
                        console.log("No data available");
                    }
                })


                this.modeParty = mode;
                this.maxMember =  member;
                this.score = temp;
                this.finalScore = temp1;
            },

            editItem (item) {
                this.editedIndex = item['.key']
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = item['.key']
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                // if (this.editedIndex != -1) {

                //     //tambahkan ini untuk edit data
                //     dessRef
                //         .child(this.editedIndex)
                //         .set(this.editedItem)
                //         .then(()=>{
                //             alert('Berhasil Edit Data!')
                //         })
                //         .catch((err)=>[
                //             alert("Gagal Edit Data: ",err)
                //         ])


                // } else {

                //     //tambahkan code ini untuk create data ke db
                //     dessRef
                //         .push(this.editedItem)
                //         .then(()=>{
                //             alert('Berhasil Tambah Data!')
                //         })
                //         .catch((err)=>[
                //             alert("Gagal Tambah Data: ",err)
                //         ])
                // }
                this.close()
            },
        },
        mounted(){
            this.getScore();
            this.arenaUid = this.$session.get("arenaUid");
            console.log(this.arenaUid);
        }
    }
</script>